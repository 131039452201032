import { track, trackAddToCart } from '@/feature/tracking/track';
import { toArray } from '@/util/array';
import { attachToDebug } from '@/util/debug';
import { cloneDeep } from 'lodash-es';
import { useState } from 'react';
import { useHasPermission } from '../permissions/PermissionsProvider';
import cartActions from './cartAction';
import CartContext from './CartContext';
import { useDefaultDeliveryPoint } from './defaultDeliveryPoint/useDefaultDeliveryPoint';
import { useUpdateCartMutation } from './mutation';
import PriceLoader from './PriceLoader';
import useLoadCart from './useLoadCart';
import useNotifications from './useNotifications';

const CartProvider = ({ children }: any) => {
  const [cart, setCart] = useState<CartDto>();
  const hasEcomPermission = useHasPermission('ecom.order:w');
  useLoadCart(setCart, hasEcomPermission);
  // Triggers three updates when called when transitioning between statuses:
  //     idle -> loading -> success / error
  // Consider rolling self-brewed solution
  const { mutate: mutateCart } = useUpdateCartMutation();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { defaultDeliveryPoint, setDefaultDeliveryPoint } =
    useDefaultDeliveryPoint();
  const [selectedDeliveryPoint, setSelectedDeliveryPoint] =
    useState<DeliveryPointDto>();
  const {
    addNotification,
    changeDeliveryAddressNotification,
    replaceNotification,
    saveErrorNotification,
  } = useNotifications();
  const selectedDeliveryPointItems =
    cart?.items.filter(
      (item) =>
        selectedDeliveryPoint &&
        item.deliveryPointEntityId === selectedDeliveryPoint?.id
    ) ?? [];

  attachToDebug('cart', cart);

  function add(...items: CartItem[]) {
    if (!cart) {
      throw new Error('Cart is not ready, unable to add items to it');
    }

    const newCart = cartActions.add(
      cart,
      ...items.map((item) => ({
        ...item,
        deliveryPointEntityId:
          item.deliveryPointEntityId ?? selectedDeliveryPoint?.id,
      }))
    );
    save(newCart);
    addNotification(...items);
    trackAddToCart(items);
  }

  function remove(...positions: number[]) {
    if (!cart) {
      throw new Error('Cart is not ready, unable to remove items from it');
    }
    const newCart = cartActions.remove(cart, positions);
    save(newCart);
  }

  function replace(fromItem: ReplaceItem, toItem: ReplaceItem) {
    if (!toItem.id) {
      console.info(
        `Trying to replace item (id: ${fromItem.id}, no: ${fromItem.no}) with item which does not exist in same (no: ${toItem.no})`
      );
      return;
    }
    if (fromItem.id === toItem.id) {
      return;
    }
    if (!cart) {
      throw new Error('Cart is not ready, unable to replace items');
    }
    if (!cart.items.find((item) => item.itemId === fromItem.id)) {
      return;
    }
    const newCart = cartActions.replace(cart, fromItem.id, toItem.id);
    save(newCart);
    replaceNotification(fromItem.no, toItem.no);
  }

  function changeQuantity(position: number, quantity: number) {
    if (!cart) {
      throw new Error('Cart is not ready, unable to edit quantity');
    }
    const newCart = cartActions.changeQuantity(cart, position, quantity);
    save(newCart);
  }

  function changeDeliveryPoint(
    deliveryPoint: DeliveryPointOption | undefined,
    ...positions: number[]
  ) {
    if (!cart) {
      throw new Error('Cart is not ready, unable to edit delivery address');
    }
    const newCart = cartActions.changeDeliveryPoint(
      cart,
      positions,
      deliveryPoint?.id
    );
    save(newCart);
    clearItemSelection(positions);
    changeDeliveryAddressNotification(newCart, positions, deliveryPoint);
  }

  function changeEquipment(
    equipmentId: string | undefined,
    ...positions: number[]
  ) {
    if (!cart) {
      throw new Error(
        'Cart is not ready, unable to edit equipment article is tagged to'
      );
    }
    const newCart = cartActions.changeEquipment(cart, positions, equipmentId);
    save(newCart);
  }

  function save(cart: CartDto) {
    if (!cart) {
      throw new Error('Cart is not ready, unable to save it');
    }

    // Update cart state (optimistic update)
    setCart(cart);

    // Save to db
    mutateCart(cart, {
      onSuccess: (savedCart) => {
        // Merge item details from response
        const { updated, updatedCart } = mergeCartInfo(cart, savedCart);
        if (updated) {
          setCart(updatedCart);
        }
      },
      onError: (error) => {
        saveErrorNotification();
        console.error(error);
      },
    });
  }

  function mergeCartInfo(cart: CartDto, savedCart: CartDto) {
    const updatedCart = cloneDeep(cart);

    // Decorate with item details
    let updated = false;
    updatedCart.items = updatedCart.items.map((item) => {
      // Has item details already
      if (item.itemDetails) {
        return item;
      }
      const itemDetails = savedCart?.items.find(
        (savedCartItem) => savedCartItem.position === item.position
      )?.itemDetails;
      updated = true;
      return { ...item, itemDetails };
    });

    return { updated, updatedCart };
  }

  function handleSelectDeliveryPoint(deliveryPoint?: DeliveryPointDto) {
    setSelectedDeliveryPoint(deliveryPoint);
    clearAllItemSelections();
  }

  function handleSelectItems(positions: number[] = []) {
    track('cart_select_items', { number_of_items: positions.length });
    setSelectedItems(positions);
  }

  function clearAllItemSelections() {
    setSelectedItems([]);
  }

  function clearItemSelection(position: number | number[]) {
    const positions = toArray(position);
    setSelectedItems(
      selectedItems.filter((itemPos) => !positions.includes(itemPos))
    );
  }

  function handlePriceReceived(price: PriceAndAvailability, articleId: number) {
    // Article is not superseded
    if (price.articleId === articleId) {
      return;
    }
    const replaceItem = cart?.items.find((item) => item.itemId === articleId);
    const fromItem = {
      id: articleId,
      no: replaceItem?.itemDetails?.articleNumber || `Id: ${articleId}`,
    };
    const toItem = {
      id: price.articleId,
      no: price.articleNumber,
    };

    replace(fromItem, toItem);
  }

  const hasWritePermit =
    selectedDeliveryPoint?.permits?.includes('ecom.order:w') ?? false;

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart: add,
        changeQuantity,
        changeDeliveryPoint,
        changeEquipment,
        removeFromCart: remove,
        isReady: Boolean(cart),
        hasWritePermit,

        selectedItems,
        setSelectedItems: handleSelectItems,

        selectedDeliveryPoint,
        setSelectedDeliveryPoint: handleSelectDeliveryPoint,
        selectedDeliveryPointItems,
        defaultDeliveryPoint,
        setDefaultDeliveryPoint,
      }}
    >
      <PriceLoader cart={cart} onPriceSuccess={handlePriceReceived} />
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
