import { notEmpty } from '@/util/array';
import { useTranslation } from '../lang';
import { DeliveryPointModel } from './useDeliveryPoints';

interface FormatAddressOptions {
  prefix?: string;
  fallback?: string;
}

export function formatAddress(
  address?: PostalAddress,
  options?: FormatAddressOptions
) {
  if (!address) {
    return [options?.prefix, options?.fallback]
      .filter((item) => !!item)
      .join(', ');
  }

  const {
    addressee,
    poBox,
    line1,
    line2,
    line3,
    city,
    subdivision,
    postalCode,
    country,
  } = address;
  return [
    options?.prefix,
    addressee,
    poBox,
    line1,
    line2,
    line3,
    city,
    subdivision,
    postalCode,
    country,
  ]
    .filter((item) => !!item)
    .join(', ');
}

export function formatDeliveryPointAddress(
  deliveryPoint?: DeliveryPointModel,
  fallback?: string
) {
  if (!deliveryPoint) {
    return '';
  }

  const prefix = formatPrefix(deliveryPoint);

  return formatAddress(deliveryPoint.address, { prefix, fallback });
}

export function useFormatDeliveryPointAddress() {
  const { texts, translate } = useTranslation();
  const fallback = translate(texts.ecom.deliveryPoint.addressMissing);

  return (deliveryPoint?: DeliveryPointDto) =>
    formatDeliveryPointAddress(deliveryPoint, fallback);
}

const formatPrefix = (deliveryPoint: DeliveryPointModel) =>
  [deliveryPoint.mdgErpAccountId, deliveryPoint.deliverySequenceId]
    .filter(notEmpty)
    .join(' ');
