import { FeatureName } from './index';

export default [
  'ecom.delivery-date',
  'ecom.list-price',
  'fleet.alarm-signal-intervals',
  'fleet.annotations',
  'fleet.claims',
  'fleet.liner-wear',
  'fleet.time-weighted-averages',
  'fleet.visualisation-tabular-data',
  'fleet.visualisation-compare-data',
  'new-data-grid',
] as const;

export const labels: Record<FeatureName, string> = {
  'ecom.delivery-date': 'Ecom estimated delivery date',
  'ecom.list-price': 'Ecom list price',
  'fleet.alarm-signal-intervals': 'Alarm thresholds',
  'fleet.annotations': 'Annotations',
  'fleet.claims': 'Warranty claims',
  'fleet.liner-wear': 'Liner wear chart for cone crushers',
  'fleet.time-weighted-averages': 'Time weighted averages',
  'fleet.visualisation-tabular-data': 'Tabular data of line chart samples',
  'fleet.visualisation-compare-data': 'Compare data in line charts',
  'new-data-grid': 'New table',
};
