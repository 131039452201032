import { ONE_HOUR } from '@/util/time';
import { UseQueryOptions } from '@tanstack/react-query';
import { useBusinessEntityQuery } from '../businessEntity/queries';
import { formatDeliveryPointAddress } from './address';
import useBusinessRelations from './useBusinessRelations';

export interface DeliveryPointModel extends DeliveryPointDto {
  mdgErpAccountId?: string;
}

export const useDeliveryPointsQuery = (
  queryOptions: UseQueryOptions<DeliveryPointDto[]> = {}
) => {
  const deliveryPointsQuery = useBusinessEntityQuery<DeliveryPointDto[]>(
    {
      type: 'mdg_delivery_point',
    },
    {
      include: ['parents', 'permits'],
    },
    {
      cacheTime: ONE_HOUR,
      ...queryOptions,
    }
  );
  const { businessRelations } = useBusinessRelations();
  const deliveryPoints: DeliveryPointModel[] | undefined =
    deliveryPointsQuery.data?.map((d) => ({
      ...d,
      mdgErpAccountId: businessRelations.find(
        (b) => b.id === d.parentIds?.at(0)
      )?.mdgErpAccountId,
    }));

  return { ...deliveryPointsQuery, data: deliveryPoints };
};

const useDeliveryPoints = () => {
  const { data = [], ...rest } = useDeliveryPointsQuery();

  const deliveryPoints = data.filter(
    (d) => d.permits?.includes('ecom.price:r') && d.mdgStatus === 'A'
  );
  const options: DeliveryPointOption[] = deliveryPoints
    .filter((d) => d.permits?.includes('ecom.order:w'))
    .map((deliveryPoint) => ({
      id: deliveryPoint.id,
      title: formatDeliveryPointAddress(deliveryPoint),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return { deliveryPoints, options, ...rest };
};

export default useDeliveryPoints;

export const useDeliveryPoint = (deliveryPointId?: string) => {
  const { data: deliveryPoints = [] } = useDeliveryPointsQuery();

  return deliveryPoints.find(
    (deliveryPoint) => deliveryPoint.id === deliveryPointId
  );
};
