import { styled } from '@mui/material/styles';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

interface CompositeTooltipProps extends TooltipProps {
  padding?: 'none' | 'normal';
}

export const CompositeTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} children={children} />
  ),
  {
    name: 'CompositeTooltip',
    slot: 'Root',
    shouldForwardProp: (propName) => propName !== 'padding',
  }
)<CompositeTooltipProps>(({ theme, padding = 'normal' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[4],
    maxWidth: 'min(500px, 90vw)',
    ...(padding === 'none' && {
      padding: 0,
    }),
  },
}));
