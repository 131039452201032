import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Components } from 'hast-util-to-jsx-runtime/lib/types';
import MD from 'react-markdown';
import { isMarkdown } from './utils';

const components: Partial<Components> = {
  h1: ({ children }) => (
    <Typography variant="subtitle1" children={children} gutterBottom />
  ),
  h2: ({ children }) => (
    <Typography variant="subtitle2" children={children} gutterBottom />
  ),
  p: ({ children }) => (
    <Typography variant="body2" children={children} sx={{ marginBottom: 1 }} />
  ),
  ul: ({ children }) => <ul style={{ marginBlock: 12 }}>{children}</ul>,
  ol: ({ children }) => <ol style={{ marginBlock: 12 }}>{children}</ol>,
  li: ({ children }) => (
    <Typography variant="body2" children={children} component="li" />
  ),
};

// Supported markdown tags
const supportedTags = ['h1', 'h2', 'p', 'ul', 'ol', 'li', 'strong', 'em'];

interface MarkdownProps {
  paper?: boolean;
  children?: string | null | undefined;
}

export const Markdown = ({ paper = false, children }: MarkdownProps) => {
  if (!isMarkdown(children)) {
    return <>{children}</>;
  }

  const markdown = (
    <MD
      allowElement={(element) => supportedTags.includes(element.tagName)}
      components={components}
      skipHtml
      children={children}
    />
  );

  if (paper) {
    return <MarkdownPaper children={markdown} />;
  }

  return markdown;
};

const MarkdownPaper = styled(Paper, { name: 'Markdown', slot: 'Paper' })(
  ({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.getGrey(20),
    boxShadow: 'unset',
  })
);
