import { Markdown } from '@/component/Markdown';
import { isMarkdown } from '@/component/Markdown/utils';
import { useTranslation } from '@/feature/lang';
import type { InterpolationValues, Translation } from '@/feature/lang/types';
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledTooltipProps extends MuiTooltipProps {
  isMarkdown?: boolean;
}

const StyledTooltip = styled(
  ({ className, ...props }: StyledTooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ),
  {
    name: 'Tooltip',
    shouldForwardProp: (propName) => propName !== 'isMarkdown',
  }
)<StyledTooltipProps>(({ isMarkdown = false }) => ({
  '.MuiTooltip-tooltip': {
    ...(isMarkdown
      ? {
          // Reduce size of markdown styling to align with standard tooltip styling
          transform: `scale(0.9) !important`,
        }
      : {
          whiteSpace: 'pre-line',
        }),
  },
}));

interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
  title?: Translation;
  values?: InterpolationValues;
  count?: number;
}

const Tooltip = ({
  title,
  values,
  count,
  children,
  ...props
}: TooltipProps) => {
  const translation = useTranslation().translate(title, values, count);

  return title ? (
    <StyledTooltip
      {...props}
      isMarkdown={isMarkdown(translation)}
      title={<Markdown children={translation} />}
    >
      {children}
    </StyledTooltip>
  ) : (
    children
  );
};

export default Tooltip;
