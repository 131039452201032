import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/component/Dialog';
import Translate from '@/component/Translate';
import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useMemo } from 'react';
import { useTexts } from '../lang';
import { labels } from './features';
import { FeatureName } from './index';
import useFeatures from './useFeatures';

interface FeatureToggleDialogProps {
  open: boolean;
  onClose: () => void;
}

const FeaturesDialog = ({ open, onClose }: FeatureToggleDialogProps) => {
  const features = useFeatures();
  const texts = useTexts();

  const handleReset = () => {
    features.reset();
    onClose();
  };

  const featureList = useMemo(() => {
    return features.list
      .map((name) => ({
        name,
        // @ts-ignore
        label: labels[name] ?? name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [features.list]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title={texts.app.features.dialog.header} />
      <DialogContent>
        <FormGroup>
          {featureList.map(({ name, label }) => (
            <FeatureSwitch name={name} label={label} key={name} />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions
        right={
          <>
            <Button variant="outlined" onClick={handleReset}>
              <Translate text={texts.component.button.common.reset} />
            </Button>
            <Button color="primary" variant="contained" onClick={onClose}>
              <Translate text={texts.component.button.common.ok} />
            </Button>
          </>
        }
      />
    </Dialog>
  );
};

export default FeaturesDialog;

interface FeatureSwitchProps {
  name: FeatureName;
  label: string | undefined;
}

const FeatureSwitch = ({ name, label }: FeatureSwitchProps) => {
  const features = useFeatures();

  const handleChange =
    (name: FeatureName) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        features.on(name);
      } else {
        features.off(name);
      }
    };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={features.includes(name)}
          onChange={handleChange(name)}
          color="primary"
          name={name}
        />
      }
      label={label ?? name}
      key={name}
    />
  );
};
